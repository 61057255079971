import styled from "styled-components"
import logo from '../assets/logo-S-removebg-preview.png'
import { Link } from "react-router-dom"
import { ReactComponent as Linkedin } from "../assets/linkedin.svg"
import { ReactComponent as Facebook } from "../assets/facebook.svg"
import { ReactComponent as Instagram } from "../assets/instagram-round-svgrepo-com.svg"
import { ReactComponent as Whatsapp } from "../assets/whatsapp-128-svgrepo-com.svg"
import i18next from "i18next"
import { forwardRef } from "react"

const Section = styled.footer`
    background: linear-gradient(180deg, #130633 80%, #0568BE);
    padding: 2rem 6rem 1rem 6rem;

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        padding: 2rem 4rem 1rem 4rem;
    }
    @media only screen and (max-width : 767px) {
        padding: 2rem 1rem 1rem 1rem;
    }
`

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media only screen and (max-width : 767px) {
        flex-direction: column;
        align-items: center;
    }
`

const InformationContainer = styled.div`
    display: flex;
    flex-direction: column;
    @media only screen and (max-width : 767px) {
        margin: 1rem 0;
        align-items: center;
    }
`

const InformationTitle = styled.h4`
    color: white;
    margin: 0 0 1rem 0;
`

const Text = styled.span`
    color: white;
    line-height: 1.4rem;
`

const StyledLink = styled(Link)`
    text-decoration: none;
    color: white;
    line-height: 1.4rem;
    cursor: pointer;

    &.network {
        width: 40px;
        height: 40px;
        margin: 0.7rem 1rem 0 0;
    }

    svg {
        filter: brightness(0) saturate(100%) invert(98%) sepia(12%) saturate(238%) hue-rotate(297deg) brightness(118%) contrast(100%);
        width: 100%;
    }

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        &.network {
            width: 30px;
            height: 30px;
        }
    }

    @media only screen and (max-width : 767px) {
        &.network {
            margin: 0.7rem 0.5rem 0 0.5rem;
        }
    }
`

const Copyright = styled.p`
    color: white;
    text-align: center;
    margin: 1rem 0 0 0;

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        margin: 2rem 0 0 0;
    }
`

const ImageContainer = styled.div`
    width: 100px;

    &.network {
        width: 40px;
        height: 40px;
        margin: 0.7rem 1rem 0 0;
    }

    svg {
        filter: brightness(0) saturate(100%) invert(98%) sepia(12%) saturate(238%) hue-rotate(297deg) brightness(118%) contrast(100%);
        width: 100%;
    }
`

const Image = styled.img`
    width: 100%;
    
`

const NetworksContainer = styled.div`
    display: flex;
`

const Footer = forwardRef(function Footer(props, ref) {
    return (
        <Section ref={ref}>
            <Container>
                <InformationContainer>
                    <InformationTitle>Contact</InformationTitle>
                    <StyledLink to="tel:0786524195">07 86 52 41 95</StyledLink>
                    <StyledLink to="mailto:contact@web-boost.fr">contact@web-boost.fr</StyledLink>
                    <NetworksContainer>
                        <StyledLink href="https://www.linkedin.com/company/web-boost-france/" target="_blank" className="network">
                            <Linkedin />
                        </StyledLink>

                        <StyledLink href="https://www.facebook.com/people/Web-Boost/100064611108499/" target="_blank" className="network">
                            <Facebook />
                        </StyledLink>

                        <StyledLink href="https://www.instagram.com/webboost75/" target="_blank" className="network">
                            <Instagram />
                        </StyledLink>

                        <StyledLink to="https://wa.me/+33666655963" target="_blank" className="network">
                            <Whatsapp />
                        </StyledLink>
                    </NetworksContainer>
                </InformationContainer>

                <InformationContainer>
                    <InformationTitle>{i18next.t("footer-legal-info")}</InformationTitle>
                    <StyledLink to="/privacy-policy" target="_blank">{i18next.t("footer-policy")}</StyledLink>
                    <StyledLink to="/legal-notice" target="_blank">{i18next.t("footer-legal")}</StyledLink>
                    <StyledLink to="/cgv" target="_blank">{i18next.t("footer-cgv")}</StyledLink>
                </InformationContainer>

                <InformationContainer>
                    <StyledLink to="/">
                        <InformationTitle>Web boost</InformationTitle>
                        <ImageContainer>
                            <Image src={logo} alt="logo de l'entreprise web boost, c'est un ruban bleu, blanc et rouge, en forme de 'W'."/>
                        </ImageContainer>
                    </StyledLink>
                </InformationContainer>
            </Container>
            <Copyright>Copyright © 2025 Web-boost. All rights reserved.  | Siret : 81486626500014</Copyright>
        </Section>
    )
})

export default Footer