import styled from "styled-components"
import { SpaceSection } from "../../utils/Atoms"
import SectionTitle from "../SectionTitle"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"
import 'swiper/css'
import 'swiper/css/navigation'
import orion from "../../assets/orion.svg"
import casiope from "../../assets/casiope.svg"
import bigbear from "../../assets/bigbear.svg"
import planet from "../../assets/orangePlanet.svg"
import { ReactComponent as SmallStar } from "../../assets/smallStar.svg"
import { ReactComponent as BigStar } from "../../assets/bigStar.svg"
import { useContext } from "react"
import { GlobalContext } from "../../utils/global"
import i18next from "i18next"
// import project1 from "../../assets/project-alt-rh.png"
// import project2 from "../../assets/project-startertraders.png"
// import project3 from "../../assets/project-digital-college.png"
// import project4 from "../../assets/project-casa-tropical.png"
// import project5 from "../../assets/project-dr-ramzi.png"
// import project6 from "../../assets/project-ecephas.png"
// import project7 from "../../assets/project-focaly.png"
// import project8 from "../../assets/project-llamas.png"
import facebook from "../../assets/Facebook.webp"
import codeur from "../../assets/Codeur.webp"
import insta from "../../assets/Instagram.webp"
import malt from "../../assets/Malt.webp"
import linkedin from "../../assets/Linkedin.webp"
import bark from "../../assets/bark-2.webp"

const CarouselContainer = styled.div`
    margin-top: 7rem;
`
const ProjectWrapper = styled.div`
    width: 300px;
    height: 300px;
    position: relative;
    
    &::before {
        content: '';
        background-color: #FFBE00;
        width: 300px;
        height: 300px;
        border-radius: 50%;
        position: absolute;
        z-index: -1;
        right: -25px;
        top: -10px;
    }

    @media only screen and (min-width : 768px) and (max-width : 1300px) {
        width: 250px;
        height: 250px;

        &::before {
            width: 250px;
            height: 250px;
            right: -15px;
        }
    }

    @media only screen and (max-width : 767px) {
        width: 200px;
        height: 200px;

        &::before {
            width: 200px;
            height: 200px;
            right: -10px;
        }
    }
`

const ProjectContainer = styled.a`
    background-color: white;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    overflow: hidden;
`

const ProjectImage = styled.img`
    &.orion {
        position: absolute;
        right: 6%;
        top: 16%;
        rotate: 32deg;
        width: 18%;
    }
    &.casiope {
        position: absolute;
        right: 12%;
        top: 81%;
        rotate: -18deg;
        width: 15%;
    }
    &.bigbear {
        position: absolute;
        right: 81%;
        top: 15%;
        width: 15%;
    }
    &.projectPlanet {
        position: absolute;
        right: 2%;
        top: 78%;
        width: 8%;
    }

    &.project {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        &.bigbear {
            top: 30%;
        }
        &.orion {
            rotate: 94deg;
            top: 19%;
            right: -2%;
        }
    }

    @media only screen and (max-width : 767px) {
        &.orion {
            right: 0%;
            top: 18%;
            rotate: -84deg;
            width: 24%;
        }
        &.projectPlanet {
            display: none;
        }
        &.casiope {
            right: 4%;
            top: 71%;
            rotate: 60deg;
            width: 29%;
        }
    }
`

const SmallStarElt = styled(SmallStar)`
    position: absolute;

    &.one {
        top: 17%;
        left: 35%;
    }
    &.two {
        top: 19%;
        left: 39%;
    }
    &.three {
        top: 34%;
        left: 30%;
    }
    &.four {
        top: 76%;
        left: 4%;
    }
    &.five {
        top: 83%;
        left: 33%;
    }
    &.six {
        top: 80%;
        left: 61%;
    }
    &.seven {
        top: 13%;
        left: 3%;
    }
    &.eight {
        top: 74%;
        left: 97%;
    }
    &.nine {
        top: 32%;
        left: 62%;
    }
    &.ten {
        top: 68%;
        left: 35%;
    }
    &.eleven {
        top: 46%;
        left: 37%;
    }
    &.twelve {
        top: 46%;
        left: 96%;
    }
    &.thirteen {
        top: 80%;
        left: 14%;
    }
    &.fourteen {
        top: 38%;
        left: 69%;
    }
    &.fifteen {
        top: 20%;
        left: 5%;
    }

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        &.two {
            top: 63%;
            left: 47%;
        }
        &.fourteen {
            top: 34%;
            left: 77%;
        }
    }

    @media only screen and (max-width : 767px) {
        &.four {
            top: 68%;
            left: 4%;
        }
        &.seven {
            top: 11%;
            left: 3%;
        }
        &.eight {
            top: 65%;
            left: 94%;
        }
        &.ten {
            top: 71%;
            left: 21%;
        }
        &.fifteen {
            top: 36%;
            left: 5%;
        }
    }
`

const BigStarElt = styled(BigStar)`
    position: absolute;

    &.one {
        top: 15%;
        left: 45%;
    }
    &.two {
        top: 80%;
        left: 6%;
    }
    &.three {
        top: 31%;
        left: 41%;
    }
    &.four {
        top: 30%;
        left: 66%;
    }
    &.five {
        top: 78%;
        left: 40%;
    }
    &.six {
        top: 82%;
        left: 64%;
    }
    &.seven {
        top: 56%;
        left: 67%;
    }
    &.eight {
        top: 18%;
        left: 60%;
    }
    &.nine {
        top: 85%;
        left: 50%;
    }
    &.ten {
        top: 10%;
        left: 7%;
    }
    &.eleven {
        top: 20%;
        left: 26%;
    }
    &.twelve {
        top: 79%;
        left: 26%;
    }
    &.thirteen {
        top: 34%;
        left: 51%;
    }
    &.fourteen {
        top: 14%;
        left: 28%;
    }
    &.fifteen {
        top: 70%;
        left: 96%;
    }

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        &.eleven {
            top: 47%;
            left: 50%;
        }
    }

    @media only screen and (max-width : 767px) {
        &.four {
            top: 34%;
            left: 87%;
        }
        &.five {
            top: 75%;
            left: 40%;
        }
        &.six {
            top: 85%;
            left: 87%;
        }
        &.seven {
            top: 44%;
            left: 10%;
        }
        &.ten {
            top: 38%;
            left: 25%;
        }
        &.eleven {
            top: 32%;
            left: 3%;
        }
        &.fourteen {
            top: 12%;
            left: 28%;
        }
        &.fifteen {
            top: 67%;
            left: 91%;
        }
    }
`

function ProjectSection() {
    const { device } = useContext(GlobalContext)

    // useGSAP(() => {
    //   gsap.from('.project-title', {
    //       y: 130,
    //       ease: "power4.out",
    //       duration: 1.8,
    //       scrollTrigger: {
    //         trigger: '.project-title',
    //         start: "top 75%"
    //       }
    //   })
    // })
    let slidesPerView

    if(device === 'desktop') {
        slidesPerView = 3
    } else if (device === 'tablet') {
        slidesPerView = 2
    } else {
        slidesPerView = 1
    }

    // const data = [
    //     {
    //         image: project1,
    //         projectLink: 'https://alt-rh.com/'
    //     },
    //     {
    //         image: project2,
    //         projectLink: 'https://www.startertraders.fr/siteweb/'
    //     },
    //     {
    //         image: project3,
    //         projectLink: 'https://digital-college.fr/'
    //     },
    //     {
    //         image: project4,
    //         projectLink: 'https://casatropical-taiba.com/'
    //     },
    //     {
    //         image: project5,
    //         projectLink: 'https://www.docteurjouida.site/'
    //     },
    //     {
    //         image: project6,
    //         projectLink: 'https://www.ecephas.com/'
    //     },
    //     {
    //         image: project7,
    //         projectLink: 'https://focaly.com/'
    //     },
    //     {
    //         image: project8,
    //         projectLink: 'https://carine-llamas-psy.fr/'
    //     }
    // ]
    const data = [
        {
            image: codeur,
            projectLink: 'https://www.codeur.com/-webboostfr'
        },
        {
            image: malt,
            projectLink: 'https://www.malt.fr/profile/julien3'
        },
        {
            image: facebook,
            projectLink: 'https://www.facebook.com/people/Web-Boost/100064611108499/'
        },
        {
            image: insta, 
            projectLink: 'https://www.instagram.com/webboost75/'
        },
        {
            image: linkedin,
            projectLink: 'https://www.linkedin.com/company/web-boost-france/'
        },
        {
            image: bark,
            projectLink: 'https://www.bark.com/fr/fr/b/web-boost/aRE8w/'
        }
    ]

    return (
        <SpaceSection className="reverse" id="project_section">
            <ProjectImage src={orion} className="orion" alt="image d'une constelation" />
            <ProjectImage src={casiope} className="casiope" alt="image d'une constelation" />
            <ProjectImage src={bigbear} className="bigbear" alt="image d'une constelation" />
            <ProjectImage src={planet} className="projectPlanet" alt="image d'une planète" />
            <SmallStarElt className="one"/>
            <SmallStarElt className="two"/>
            <SmallStarElt className="three"/>
            <SmallStarElt className="four"/>
            <SmallStarElt className="five"/>
            <SmallStarElt className="six"/>
            <SmallStarElt className="seven"/>
            <SmallStarElt className="eight"/>
            <SmallStarElt className="nine"/>
            <SmallStarElt className="ten"/>
            <SmallStarElt className="eleven"/>
            <SmallStarElt className="twelve"/>
            <SmallStarElt className="thirteen"/>
            <SmallStarElt className="fourteen"/>
            <SmallStarElt className="fifteen"/>

            <BigStarElt className="one"/>
            <BigStarElt className="two"/>
            <BigStarElt className="three"/>
            <BigStarElt className="four"/>
            <BigStarElt className="five"/>
            <BigStarElt className="six"/>
            <BigStarElt className="seven"/>
            <BigStarElt className="eight"/>
            <BigStarElt className="nine"/>
            <BigStarElt className="ten"/>
            <BigStarElt className="eleven"/>
            <BigStarElt className="twelve"/>
            <BigStarElt className="thirteen"/>
            <BigStarElt className="fourteen"/>
            <BigStarElt className="fifteen"/>

            <SectionTitle label={i18next.t("project-section-title")} id="project-title" className={"white"}/>
            <CarouselContainer>
                <Swiper
                    loop={true}
                    slidesPerView={slidesPerView}
                    modules={[Navigation]}
                    navigation={true}
                    spaceBetween={10}
                >
                    {data.map((project, index) => (
                        <SwiperSlide key={index}>
                            <ProjectWrapper>
                                <ProjectContainer href={project.projectLink} target="_blank">
                                    <ProjectImage className="project" src={project.image} alt="aperçu du projet" />
                                </ProjectContainer>
                            </ProjectWrapper>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </CarouselContainer>
        </SpaceSection>
    )
}

export default ProjectSection