import styled from "styled-components"
import SectionTitle from "../SectionTitle"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"
import { Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import { useContext } from "react"
import { GlobalContext } from "../../utils/global"
import review1 from "../../assets/Avis1.webp"
import review2 from "../../assets/Avis2.webp"
import review3 from "../../assets/Avis3.webp"
import review4 from "../../assets/Avis4.webp"
import review5 from "../../assets/Avis5.webp"
import review6 from "../../assets/Avis6.webp"
import review7 from "../../assets/Avis7.webp"
import i18next from "i18next"

const Section = styled.section`

`

const CarouselContainer = styled.div`
    width: 95%;
    margin: auto;

    & .swiper-button-prev, .swiper-button-next {
        color: #130633;
    }
`

const ReviewWrapper = styled.div`
    margin: 2rem 1rem;
`

const Image = styled.img`
    width: 100%;
    height: auto;
`

function CustomerReviewSection () {
    const { device } = useContext(GlobalContext)
    let slidesPerView

    if(device === 'desktop') {
        slidesPerView = 2
    } else {
        slidesPerView = 1
    }

    const data = [ review1, review2, review3, review4, review5, review6, review7 ]

    return (
        <Section>
            <SectionTitle label={i18next.t("customer-review-section-title")} id="review-customer-title" />
            <CarouselContainer>
                <Swiper
                    loop={true}
                    slidesPerView={slidesPerView}
                    modules={[Navigation, Autoplay]}
                    navigation={true}
                    spaceBetween={10}
                    autoplay={{ delay: 2000 }}
                >
                    {data.map((image, index) => (
                        <SwiperSlide key={index}>
                            <ReviewWrapper>
                                <Image src={image} alt="aperçu de l'avis client" />
                            </ReviewWrapper>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </CarouselContainer>
        </Section>
    )
}

export default CustomerReviewSection